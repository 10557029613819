import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import { getCourseNavigation } from "../../../../../store/courses"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/landschaften-und-orte" />
      }
      navigation={navigation}
    >
      <Seo title="Reiseformen im Gedicht" />
      <Stack>
        <Heading as="h2" level={2}>
          Reiseformen im Gedicht
        </Heading>
        <Paragraph>
          ›Andenken‹ beschreibt in erster Linie eine Reiseerinnerung oder
          Gedankenreise.
        </Paragraph>
        <Paragraph>
          Jemand, ein lyrisches Ich, erinnert sich zurück und ruft sich Bilder
          einer vergangenen Reise ins Gedächtnis. Schon der Titel, ›Andenken‹,
          weist darauf hin und der Beginn der 2. Strophe markiert dies noch
          einmal: »Noch denket das mir wohl« - eine im Schwäbischen mundartlich
          gebrauchte Wendung, die so viel heißt wie: »Ich kann mich noch gut
          daran erinnern«. Angestoßen wird diese Erinnerung durch den
          Nordostwind, der die Gedanken des lyrischen Ichs in Richtung Südwesten
          mitnimmt.
        </Paragraph>
        <Paragraph>
          In den Erinnerungen, die dabei aufgerufen werden, tauchen jedoch auch
          andere Reiseformen auf. Insbesondere die Seefahrt, mit der das Gedicht
          beginnt und endet und dabei auch Assoziationen an die abenteuerlichen
          See-Expeditionen von Christoph Kolumbus, James Cook und Alexander von
          Humboldt wachruft. Aber auch Beschreibungen, die von Spaziergängen und
          Wanderungen, einer Städtereise, einem Strandurlaub mitgebracht sein
          könnten, finden sich darin. Dazwischen erinnern einzelne Passagen an
          Szenen eines Traums oder einer Traumreise.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
